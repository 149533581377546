import React, { useContext, Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  F,
  flatten,
  T,
  prop
} from 'ramda'
import { compose, lifecycle } from 'recompose'
import { connect } from 'react-redux'
import { withScreenWidthWatching } from '../hoc/with-screen-width-watching'
import { checkIsAuthenticated } from '../lib/auth'
import AsyncSeries from './series'
import AsyncTitle from './title'
import Callout from '../containers/callout'
import Carousel from '../containers/carousel'
import Category from '../containers/category'
import EnhancedGrid from './grid/index'
import BrandScreenHeader from './brandScreenHeader/index'
import HotSpot from '../containers/hotspot'
import Markdown from './markdown'
import MediaBar from './media-bar'
import PromotionalTiles from './promotional-tiles'
import VideoFeature from '../containers/video-feature'
import PromotionalGrid from '../containers/promotional-grid'
import Topgradient from './shared/top-gradient'
import Spacer from './shared/spacer'
import Banner, { BannerContentType } from './banner'
import HTML from './html'
import { HotspotVideoContentType } from './hotspot/hotspot-video'
import HotspotVideoStream from './hotspot/hotspot-video/hotspot-video-stream'
import { useIsKid } from '../hooks/useIsKid'

export const ComponentsContext = React.createContext()
// below is for testing purpose
export const useComponentsContext = () => useContext(ComponentsContext)

function ComponentsList({
  components = [], // eslint-disable-line
  isContentItemOnMyList,
  addToMyList,
  removeFromMyList,
  theme,
  playbackInfoMany,
  screenTitle,
  screenWidth,
  isAuthenticated,
  marketComponent
}) {
  const hotspotVideoContent = prop('videoHotspot', marketComponent)
  const bannerContent = prop('banner', marketComponent)
  const isKid = useIsKid()

  return (
    <>
      <ComponentsContext.Provider value={components}>
        {flatten(
          components.map((component, index) => {
            switch (component.__typename) {
              case 'TitleComponent':
                return (
                  <AsyncTitle
                    theme={theme}
                    key={component.title.id}
                    component={component}
                    screenWidth={screenWidth}
                    addToMyList={addToMyList}
                    removeFromMyList={removeFromMyList}
                    isContentItemOnMyList={isContentItemOnMyList}
                  />
                )
              case 'SeriesComponent':
                return (
                  <AsyncSeries
                    theme={theme}
                    key={component.series.id}
                    component={component}
                    screenWidth={screenWidth}
                    addToMyList={addToMyList}
                    removeFromMyList={removeFromMyList}
                    isContentItemOnMyList={isContentItemOnMyList}
                  />
                )
              case 'Callout':
                return <Callout key={component.id} callout={component} />
              case 'Carousel': {
                const carousel = (
                  <Carousel
                    theme={theme}
                    key={component.id}
                    isContentItemOnMyList={isContentItemOnMyList}
                    addToMyList={addToMyList}
                    removeFromMyList={removeFromMyList}
                    carousel={component}
                    carouselPosition={index}
                    playbackInfoMany={playbackInfoMany}
                    screenTitle={screenTitle}
                  />
                )

                const bannerPositions = {
                  '/': isAuthenticated ? 7 : 2,
                  '/tv': isAuthenticated ? 7 : -1,
                  '/movies': isAuthenticated ? 7 : -1,
                  '/rental': isAuthenticated ? 3 : -1
                }

                const shouldRenderBanner =
                  bannerContent &&
                  !isKid &&
                  bannerPositions[location.pathname] === index

                // banner should not be visible to kids profile
                if (shouldRenderBanner) {
                  return (
                    <Fragment key={component.id}>
                      <Banner content={bannerContent} />
                      {carousel}
                    </Fragment>
                  )
                }

                const spacer = <Spacer height={64} key="carousel-spacer" />
                const shouldRenderCarouselWithSpacing =
                  isAuthenticated || location.pathname !== '/'
                const hasSearchParams =
                  location.pathname.indexOf('?search=') !== -1

                if (shouldRenderCarouselWithSpacing && hasSearchParams) {
                  return (
                    <Fragment key={component.id}>
                      {index === 0 ? spacer : null}
                      {carousel}
                    </Fragment>
                  )
                }

                // Render carousel without spacing
                return <Fragment key={component.id}>{carousel}</Fragment>
              }
              case 'Hotspot': {
                const hotspot = (
                  <HotSpot
                    theme={theme}
                    key={component.id}
                    hotspot={component}
                    hotspotPosition={index}
                    playbackInfoMany={playbackInfoMany}
                  />
                )

                const rendered =
                  index === 0 && theme !== 'LIGHTBOX_KIDS' ? (
                    <Fragment key={component.id}>
                      <Topgradient key="hotspot-gradient" />
                      {hotspot}
                    </Fragment>
                  ) : (
                    hotspot
                  )

                if (hotspotVideoContent) {
                  return (
                    <HotspotVideoStream
                      key={component.id}
                      content={hotspotVideoContent}
                      fallback={rendered}
                    />
                  )
                }
                return rendered
              }
              case 'Grid':
                return (
                  <EnhancedGrid
                    theme={theme}
                    isContentItemOnMyList={isContentItemOnMyList}
                    addToMyList={addToMyList}
                    removeFromMyList={removeFromMyList}
                    key={component.id}
                    grid={component}
                    gridPosition={index}
                  />
                )
              case 'CategoryComponent':
                return (
                  <Category
                    theme={theme}
                    key={component.id}
                    category={component}
                  />
                )
              case 'Markdown':
                return <Markdown key={component.id} markdown={component} />
              case 'HTML':
                return <HTML key={component.id} html={component} />
              case 'MediaBar': {
                const mediaBar = (
                  <MediaBar key={component.id} mediaBar={component} />
                )

                return mediaBar
              }
              case 'PromotionalTileComponent':
                return (
                  <PromotionalTiles
                    key={component.id}
                    promotionalTiles={component}
                  />
                )
              case 'VideoFeature':
                return <VideoFeature key={component.id} component={component} />
              case 'PromotionalGridComponent':
                return (
                  <PromotionalGrid
                    key={component.id}
                    promotionalGrid={component}
                  />
                )
              case 'BrandScreenHeader':
                return (
                  <BrandScreenHeader
                    key={component.id}
                    brandScreenHeader={component}
                  />
                )
              default:
                return null
            }
          })
        )}
      </ComponentsContext.Provider>
    </>
  )
}

ComponentsList.propTypes = {
  isContentItemOnMyList: PropTypes.func,
  theme: PropTypes.string.isRequired,
  addToMyList: PropTypes.func,
  removeFromMyList: PropTypes.func,
  playbackInfoMany: PropTypes.oneOfType([PropTypes.object]),
  components: PropTypes.oneOfType([PropTypes.array]),
  screenTitle: PropTypes.string,
  screenWidth: PropTypes.number.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  marketComponent: PropTypes.shape({
    banner: BannerContentType,
    videoHotspot: HotspotVideoContentType
  }).isRequired
}

ComponentsList.defaultProps = {
  isContentItemOnMyList: F,
  addToMyList: T,
  removeFromMyList: T,
  playbackInfoMany: null,
  screenTitle: null
}

export default compose(
  withScreenWidthWatching,
  connect(
    state => ({
      isAuthenticated: checkIsAuthenticated(state)
    }),
    dispatch => ({
      dispatch
    })
  ),
  lifecycle({
    componentDidMount() {
      window.scrollTo(0, 0)
    }
  })
)(ComponentsList)
