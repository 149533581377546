import {
  compose, branch, renderNothing, renderComponent
} from 'recompose'
import { connect } from 'react-redux'
import { STATIC_SCREENS } from './constants'
import App from './index'
import { LoadingModal } from './components/loading/modal'

const mapStateToProps = (state) => {
  return {
    appIsLoading: state.app.isLoading
  }
}

const Gatekeeper = compose(
  connect(mapStateToProps),
  branch(
    ({ appIsLoading }) => (
      appIsLoading
    ),
    renderNothing
  ),
  branch(
    () => {
      return (
        location.pathname === STATIC_SCREENS.EVERGENT_IFRAME_RETURN_URL.creditCardForm
      )
    },
    renderComponent(LoadingModal)
  )
)

export default Gatekeeper(App)
