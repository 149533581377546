
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AgeGroupOptionFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AgeGroupOption"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"label"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"maxRating"},"arguments":[],"directives":[]}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ParentalRatingOptionFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ParentalRatingOption"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"rating"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"label"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"ratingOrder"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":172}};
    doc.loc.source = {"body":"fragment AgeGroupOptionFields on AgeGroupOption {\n  id\n  label\n  maxRating\n}\nfragment ParentalRatingOptionFields on ParentalRatingOption {\n  rating\n  label\n  ratingOrder\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
