import React from 'react'
import { propType } from 'graphql-anywhere'
import { Background } from 'react-imgix'

import { IMGIX_CROP_OPTIONS } from '../../constants'
import styles from './brandScreenHeader.css'
import BRAND_SCREEN_HEADER_FRAGMENT from '../../../graphql/fragments/brand-screen-header.gql'

const BrandScreenHeader = React.memo(({
  brandScreenHeader: {
    brandHeroImageUrl,
    brandLogoImageUrl,
    brandDesc
  }
}) => {
  // The brand hero image is required for brand screen. But the logo and description are optional
  if (!brandHeroImageUrl) {
    return null
  }

  return (
    <div
      className={styles.container}
    >
      <div className={styles.bottomGradient} />

      <Background
        className={styles.image}
        type="img"
        src={brandHeroImageUrl}
        imgixParams={{ crop: IMGIX_CROP_OPTIONS.BRAND, fit: 'min' }}
        precision={50}
      />

      <div className={styles.brandLogoFrame}>
        { brandLogoImageUrl && <div className={styles.logoImageFrame}><img src={brandLogoImageUrl} alt="Brand Logo" className={styles.logoImage} /></div> }
        <p>{brandDesc}</p>
      </div>
    </div>
  )
})

BrandScreenHeader.propTypes = {
  brandScreenHeader: propType(BRAND_SCREEN_HEADER_FRAGMENT).isRequired
}

export default BrandScreenHeader
