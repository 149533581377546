import React from 'react'
import { defaultTo } from 'ramda'
import PropTypes from 'prop-types'
import ReactImgix from 'react-imgix'
import classNames from 'classnames'
import { createUseStyles } from 'react-jss'

import recipes from './recipes'

const imgixStyle = {
  width: '100%',
  height: '100%',
  position: 'relative',
  backgroundPosition: 'top',
  objectFit: 'cover',
  objectPosition: 'top'
}

const useStyles = createUseStyles({
  container: {
    background: '#1F1F1F',
    display: 'flex',
    flex: '0 0 100%'
  },

  circularImageFrame: {
    borderRadius: '50% !important'
  },

  imgix: {
    ...imgixStyle
  },

  imgixExcludeKids: {
    ...imgixStyle,
    borderRadius: '6px'
  }
})

const CarouselImgix = React.memo(({
  src, recipe, shouldCrop, isKids, isCircular
}) => {
  const styles = useStyles()
  const recipeConfig = defaultTo({}, recipes[recipe] || recipe)

  let width = 0
  let height = 0
  if (recipeConfig.width) {
    width = recipeConfig.width
    height = width * recipeConfig.ratio
  } else {
    height = recipeConfig.height
    width = height / recipeConfig.ratio
  }

  const cropToRatio = shouldCrop ? {
    ar: `1:${recipeConfig.ratio}`,
    fit: 'crop'
  } : null

  return (
    <div className={classNames(styles.container, isCircular && styles.circularImageFrame)} data-testid="carousel-imgix-container">
      <ReactImgix
        src={src}
        width={width}
        height={height}
        className={classNames(isKids ? styles.imgix : styles.imgixExcludeKids, isCircular && styles.circularImageFrame)}
        imgixParams={cropToRatio}
      />
    </div>
  )
})

CarouselImgix.propTypes = {
  src: PropTypes.string.isRequired,
  recipe: PropTypes.oneOf([...Object.keys(recipes), ...Object.values(recipes)]),
  shouldCrop: PropTypes.bool,
  isKids: PropTypes.bool,
  isCircular: PropTypes.bool
}

CarouselImgix.defaultProps = {
  shouldCrop: false,
  isKids: false,
  isCircular: false
}

export default CarouselImgix
