import {
  compose, lifecycle, withProps, branch, renderNothing
} from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import qs from 'query-string'
import { graphql } from 'react-apollo'

import {
  selectDataSaverOption,
  castSenderConnected,
  castSenderDisconnected,
  updateAuthTokenAndDefaultTheme as updateAuthTokenAndDefaultThemeAction,
  setManageSubsOptions
} from '../actions'
import Main from '../main'
import initCastSender from '../lib/cast-sender'
import ReduxApolloSynchronizer from '../auth/redux-apollo-synchronizer'
import getDeviceInfo from '../lib/device-info'
import withDeviceFingerprint from '../hoc/with-device-fingerprint'
import CONFIG_QUERY from '../../graphql/queries/config.gql'
import ACCOUNT_QUERY from '../../graphql/queries/account.gql'

import { initYouboraPlugin } from '../lib/player'
import { mergeQueryParams } from '../lib/location'
import { getAccountIdFromConfig } from '../selectors/config'
import {
  getTokenFromState,
  getAccountIdFromState,
  getSelectedProfileFromState
} from '../selectors/session'
import { checkIsAuthenticated } from '../lib/auth'
import { initDomPurify } from '../utils'
import { segmentIdentify } from '../segment/segment-identify'
import { STORAGE_KEY_PARENTAL_RATINGS } from '../storage-keys'

const enhance = compose(
  withRouter,
  withDeviceFingerprint,
  ReduxApolloSynchronizer,
  connect(
    state => ({
      accountId: getAccountIdFromState(state),
      selectedProfile: getSelectedProfileFromState(state),
      theme: state.theme,
      token: getTokenFromState(state),
      isAuthenticated: checkIsAuthenticated(state)
    }),
    // mapDispatchToProps
    (dispatch, { accountId }) => ({
      selectDataSaver: dataSaverOption => {
        dispatch(selectDataSaverOption(dataSaverOption, accountId))
      },
      updateCastSenderStatus: connected => {
        if (!connected) {
          dispatch(castSenderDisconnected())
          return
        }
        dispatch(castSenderConnected())
      },
      updateAuthTokenAndDefaultTheme: (token, theme) => {
        dispatch(updateAuthTokenAndDefaultThemeAction(token, theme))
      },
      updateManageSubsOptions: (manageSubsOptions) => {
        dispatch(setManageSubsOptions(manageSubsOptions))
      },
      dispatch
    })
  ),
  withProps(({ location }) => {
    const parsedSearch = qs.parse(location && location.search)
    const jwt = parsedSearch && parsedSearch.jwt
    return { jwt }
  }),
  withProps(({ deviceFingerprint }) => {
    return {
      deviceInfo: {
        ...getDeviceInfo(),
        uuid: deviceFingerprint,
        platform: 'Web'
      }
    }
  }),
  graphql(CONFIG_QUERY, {
    name: 'configQuery',
    options: ({ jwt, deviceInfo }) => {
      return {
        variables: {
          input: {
            deviceInfo
          }
        },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        // we provide the jwt via context if one is provided by the url
        context: jwt ? { headers: { authorization: `bearer ${jwt}` } } : null
      }
    },
    props: ({ ownProps, configQuery: { loading, config, parentalRatings } }) => ({
      ...ownProps,
      configLoading: loading,
      appConfig: config,
      parentalRatings,
      configAccountId: getAccountIdFromConfig(config)
    })
  }),
  branch(
    ({ jwt }) => jwt, // if we have a jwt from URL,
    lifecycle({
      componentDidMount() {
        const { history, location } = this.props
        history.replace(mergeQueryParams(location, { jwt: undefined })) // remove it
      }
    })
  ),
  graphql(ACCOUNT_QUERY, {
    name: 'accountQuery',
    skip: ownProps => !ownProps.isAuthenticated,
    fetchPolicy: 'cache-first',
    props: ({ ownProps, accountQuery: { loading, account } }) => ({
      ...ownProps,
      accountLoading: loading,
      manageSubsOptions: account?.subscription?.manageSubsOptions,
      account
    })
  }),
  branch(
    ({ configLoading, accountLoading }) => configLoading || accountLoading,
    renderNothing
  ),
  lifecycle({
    UNSAFE_componentWillMount() {
      const {
        appConfig,
        theme,
        updateAuthTokenAndDefaultTheme,
        manageSubsOptions,
        updateManageSubsOptions,
        parentalRatings
      } = this.props
      if (!appConfig) {
        return
      }

      localStorage.setItem(STORAGE_KEY_PARENTAL_RATINGS, JSON.stringify(parentalRatings))

      if (
        appConfig.session &&
        appConfig.session.token &&
        this.props.token !== appConfig.session.token
      ) {
        updateAuthTokenAndDefaultTheme(appConfig.session.token, theme)
      }

      if (manageSubsOptions) updateManageSubsOptions(manageSubsOptions)

      // Initialize Youbora plugin
      initYouboraPlugin(appConfig)

      // Initialize the dom purify
      initDomPurify(appConfig)
    },
    componentDidMount() {
      initCastSender(this.props)
    },
    componentDidUpdate(prevProps) {
      const {
        accountId: newAccountId,
        appConfig: newAppConfig,
        updateAuthTokenAndDefaultTheme,
        configAccountId: newConfigAccountId,
        manageSubsOptions: newManageSubsOptions,
        updateManageSubsOptions
      } = this.props

      const {
        configAccountId: prevConfigAccountId,
        theme: prevTheme,
        manageSubsOptions: prevManageSubsOptions
      } = prevProps

      if (
        newConfigAccountId !== prevConfigAccountId &&
        newConfigAccountId !== newAccountId
      ) {
        updateAuthTokenAndDefaultTheme(newAppConfig.session.token, prevTheme)
      }

      if (newManageSubsOptions !== prevManageSubsOptions) updateManageSubsOptions(newManageSubsOptions)
      // Add segment data identify for user who signed in
      if (this.props.account) {
        const currentProfile = this.props.account.profiles?.find(item => item.id === this.props.account.selectedProfile)
        segmentIdentify(this.props.account, currentProfile, true)
      }
    }
  })
)

export default enhance(Main)
