import {
  any,
  path,
  propEq,
  propOr,
  match,
  pipe,
  ifElse,
  head,
  identity,
  pathOr,
  find,
  findIndex,
  curryN,
  pathEq,
  either,
  replace,
  pluck,
  flatten,
  nth,
  startsWith,
  defaultTo,
  take,
  last,
  compose,
  curry,
  flip,
  length,
  nthArg
} from 'ramda'
import {
  THEME_OPTIONS,
  UUID_SLUG,
  APP_PADDING
} from '../constants'
import { getLocationBase } from './location'
import { lensIso } from './fp'
import { STORAGE_KEY_PARENTAL_RATINGS } from '../storage-keys'

export const getEnableAutoplayTrailer = (contentItem) => {
  const videoExtras = contentItem.videoExtras?.filter(item => item.disableAutoplay === false)
  return { ...contentItem, videoExtras }
}

export const getFirstTrailer = path(['videoExtras', 0])

// eslint-disable-next-line
export const getPlayerJSUrl = (videoCloudAccountId, videoCloudPlayerId) => (
  `//players.brightcove.net/${videoCloudAccountId}/${videoCloudPlayerId}_default/index.min.js`
)

export const parseDecimalInt = curry(flip(parseInt))(10)
export const stringIntegerIso = lensIso(
  parseDecimalInt,
  toString
)

export function getDetailsUrl(contentItem) {
  if (contentItem.path) {
    return contentItem.path
  }
  // When episodes has not path default to series path
  if (contentItem.__typename === 'Episode' && contentItem.series) {
    return path(['series', 'path'], contentItem) || `/${UUID_SLUG}/${contentItem.series.id}`
  }
  return `/${UUID_SLUG}/${contentItem.id}`
}

export function getDetailsFullUrl(contentItem) {
  return `${getLocationBase()}${getDetailsUrl(contentItem)}`
}

export const getFirstEpisodeId = pipe(
  pathOr([], ['seasons']),
  pluck('episodes'),
  flatten,
  head,
  propOr(undefined, 'id')
)

export function getWatchUrl(contentItem) {
  let url = getDetailsUrl(contentItem)

  // Launch trailer if videoExtra
  if (contentItem.__typename === 'VideoExtra') {
    const videoExtraId = contentItem.id

    if (contentItem.contentItems) {
      // get the first title or series in the array of items that have this trailer
      const items = contentItem.contentItems.filter(item => propEq('__typename', 'Series', item) || propEq('__typename', 'Title', item))
      const contentId = path(['0', 'id'], items)

      url = `/trailer/${videoExtraId}/${contentId}`
    } else {
      url = `/trailer/${videoExtraId}`
    }
  }

  // Launch trailer if coming soon
  if (contentItem.isComingSoon) {
    const trailer = getFirstTrailer(contentItem)
    const trailerId = trailer && trailer.id

    if (length(contentItem.videoExtras)) {
      url = `/trailer/${trailerId}/${contentItem.id}`
    }
  }

  // Launch display if episode
  if (contentItem.__typename === 'Episode' || contentItem.__typename === 'Title') {
    url = `/watch/${contentItem.id}`
  }

  // Launch first episode on the first season
  if (contentItem.__typename === 'Series') {
    // If the season has no seasons / episodes retrieves it's own id.
    // Player will fetch all seasons and episodes and redirect to /watch/<episode_id>
    const episodeId = getFirstEpisodeId(contentItem) || contentItem.id
    url = `/watch/${episodeId}`
  }

  return url
}

export function getWatchFullUrl(contentItem) {
  return `${getLocationBase()}${getWatchUrl(contentItem)}`
}

/**
 * Check contentItem's typename
 * If Series or Title, get the detailed url to go to the detail page
 * If VideoExtra, get the watch url to redirect to the player
 */
export function getHotspotCTAUrl(contentItem) {
  if (contentItem.__typename === 'Series' || contentItem.__typename === 'Title') {
    return getDetailsUrl(contentItem)
  }
  return getWatchUrl(contentItem)
}

export function pluralize(count, noun, suffix = 's') {
  return `${count} ${noun}${count !== 1 ? suffix : ''}`
}

export function isIE() { // Taken from fingerprintjs2
  if (navigator.appName === 'Microsoft Internet Explorer') {
    return true
  } if (navigator.appName === 'Netscape' && /Trident/.test(navigator.userAgent)) { // IE 11
    return true
  }
  return false
}

/**
 * Tests whether or not the target rating is considered to be higher (less
 * restrictive) than the current one given.  null is considered a max
 * rating.
 *
 * @param String currentRating parental rating or null
 * @param String targetRating
 */
export function hasHigherParentalRating(currentRating, targetRating) {
  const parentalRatings = JSON.parse(localStorage.getItem(STORAGE_KEY_PARENTAL_RATINGS) || '[]')
  const current = parentalRatings.find(item => item.rating === currentRating)?.ratingOrder || 0
  const target = parentalRatings.find(item => item.rating === targetRating)?.ratingOrder || 0

  return (current < target)
}

export function isContentItemOnMyList(contentItemId, myList = []) {
  return any(item => item.id === contentItemId, myList)
}

export function isContentItemOnMyRentals(contentItemId, myRentals) {
  return myRentals && myRentals.find(item => item.contentItem &&
    item.contentItem.id === contentItemId) !== undefined
}

/**
 * For use with shouldUpdate recompose function.
 *
 * Tests whether the nextProps arg (second argument to shouldUpdate)
 * has a data.screen.components list with more than one item.
 *
 * Where data.screen.components is of length 0 or undefined, return false.
 * Where data.screen.components is of length greater than 0, return true.
 */
export const nextPropsDataHasScreenComponents = compose(
  Boolean,
  length,
  path(['data', 'screen', 'components']),
  nthArg(1)
)

/**
 * For use with shouldUpdate recompose function.
 *
 * Check if the location within props have been changed.
 * This will ONLY compare reference of location changes.
 *
 * return true if changes happens.
 */
export const isLocationChange = (
  currentProps,
  nextProps
) => nextProps.location !== currentProps.location

// For Parental Ratings Display
const matchAndSplitNumber = match(/\d+/g)

const ratingHasNumber = pipe(
  matchAndSplitNumber,
  length
)

const returnTheRatingNumber = pipe(
  matchAndSplitNumber,
  head
)

/**
 * This takes the `contentItem` and returns
 * either the number of the rating, the rating (letter) itself,
 * or an empty string (if the value is empty for whatever reason)
 */
export const getContentParentalRatingDisplay = pipe(
  pathOr('', ['rating', 'rating']),
  ifElse(
    ratingHasNumber,
    returnTheRatingNumber,
    identity // otherwise, return the value
  )
)

export function getSeasonRange(seasons, separator = '-') {
  if (!seasons.length) {
    return null
  }

  const firstSeason = path(['0', 'seasonNumber'], seasons)
  const lastSeason = last(seasons).seasonNumber
  return firstSeason !== lastSeason ? `${firstSeason}${separator}${lastSeason}` : firstSeason
}

/**
 * Used with `navHeader` and `navFooter` queries.
 */
export const findSection = curryN(2, (name, nav) => find(
  pathEq(['name'], name)
)(nav))

export const findLinks = curryN(2, (name, nav) => pipe(
  findSection(name),
  pathOr([], ['children'])
)(nav))

// Takes a GQL query data payload.
// Returns the values of either `loading` or `error`.
// Defaults to `false` if neither exist.
export const checkQueryStatus = either(
  propOr(false, 'loading'),
  propOr(false, 'error')
)

// Takes `navHeaderQuery`
// Returns main section links
export const getMainNavHeaderSectionLinks = pipe(
  propOr([], 'navHeader'),
  findLinks('header.main')
)

// Decodes HTML entities into string characters
// i.e decodeHTMLEntity('Foo&#039;s VISA') = 'Foo's VISA'
const htmlEntitiesMatcher = /&#(\d{1,4});/g
export const decodeHTMLEntity = replace(htmlEntitiesMatcher, pipe(
  nthArg(1),
  String.fromCharCode
))

// Takes main section links and return the number of the properties
// before the discover nav
export const getPropertiesCount = findIndex(propEq('isDiscoverNav', true))

// Takes content item year and rating
// Returns "year | rating" and variations if the values are null
export const getYearAndRatingText = (year, rating) => {
  if (rating) {
    if (year) {
      return `${year} | ${rating}`
    }
    return `${rating}`
  }
  return year ? `${year}` : ''
}

// Takes seasons data
// Returns year range
// i.e. (1999-2005), (1999) or (2005)
export const getYearRange = (seasons) => {
  if (seasons.length === 0) {
    return null
  }

  if (seasons.length === 1) {
    const year = pathOr(null, ['0', 'year'], seasons)
    return year ? `(${year})` : null
  }

  // get min and max
  let minYear = null
  let maxYear = null
  seasons.forEach((season) => {
    minYear = (season.year && (minYear > season.year || !minYear)) ? season.year : minYear
    maxYear = (season.year && (maxYear < season.year || !maxYear)) ? season.year : maxYear
  })

  if (!minYear && !maxYear) { // both null
    return null
  }
  if (minYear && maxYear && minYear !== maxYear) { // range
    return `(${minYear}-${maxYear})`
  }
  if (minYear) {
    return `(${minYear})`
  }
  return `(${maxYear})`
}

export function buildWhiteThemFilter(blackList) {
  return (location, isAuthenticated) => {
    const { pathname, search } = location
    const containAsPartOfString = str1 => (str2) => {
      const getUrl = pathStr => nth(1)(pathStr.split('/'))

      const currentPath = str1.indexOf('?') !== -1 ? str1 : getUrl(str1)
      if (!currentPath) return false
      if (currentPath.indexOf('?') !== -1) {
        return startsWith(str2, currentPath)
      }
      return getUrl(str2) === currentPath
    }

    // Neon 2.0: Unauthenticated home screen requires dark theme.
    //           We should probably refactor and remove this abstraction
    //           all together at some point.
    if (!isAuthenticated && pathname === '/') {
      return false
    }

    return !isAuthenticated && !any(containAsPartOfString(search || pathname))(blackList)
  }
}

export const isKids = theme => theme === THEME_OPTIONS.light

export const getPriceForFormat = (contentItem, format) => {
  return compose(
    propOr('', 'currentPrice'),
    defaultTo({}),
    find(propEq('streamVideoFormat', format)),
    propOr([], 'products')
  )(contentItem)
}

export const getTwoGenres = (contentItem) => {
  return contentItem.genres ? take(2, contentItem.genres) : null
}

export const slipnslideIsMobile = window.innerWidth < 768

export function slipnslideCalcTileShowCount(screenWidth, minNumber) {
  if (screenWidth < 768) {
    return minNumber
  } if (screenWidth < 1024) {
    return minNumber + 1
  } if (screenWidth < 1200) {
    return minNumber + 2
  } if (screenWidth < 1440) {
    return minNumber + 3
  } if (screenWidth < 1920) {
    return minNumber + 4
  }
  return minNumber + 5
}

export function slipnslideGetMargin(windowWidth) {
  if (windowWidth <= 480) {
    return APP_PADDING.DEFAULT
  } if (windowWidth <= 768) {
    return APP_PADDING.MIN_480
  }
  return APP_PADDING.MIN_768
}

export function slipnslideCalcSeasonShowCount(screenWidth, minNumber) {
  if (screenWidth < 360) {
    return minNumber + 2
  } if (screenWidth < 480) {
    return minNumber + 3
  } if (screenWidth < 667) {
    return minNumber + 4
  } if (screenWidth < 1024) {
    return minNumber + 5
  } if (screenWidth < 1440) {
    return minNumber + 6
  } if (screenWidth < 1920) {
    return minNumber + 7
  }
  return minNumber + 8
}

export function maxDescriptionLines(screenWidth) {
  if (screenWidth < 1280) {
    return 20
  } if (screenWidth < 1440) {
    return 7
  } if (screenWidth < 1920) {
    return 8
  }
  return 10
}
