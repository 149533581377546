import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { propType } from 'graphql-anywhere'
import {
  pipe, anyPass, equals, not, T
} from 'ramda'
import ReactImgix from 'react-imgix'
import classnames from 'classnames'
import OnVisible from 'react-on-visible'

import TileOverlay from '../../../containers/carousel/tile/overlay'
import TileBadge from '../../shared/badge'
import CarouselImgix from '../../imgix/carousel-imgix'

import { getTileRecipe } from '../../imgix/recipes'
import { getDetailsUrl } from '../../../lib/utils'
import RECOMMENDED_TILES_FRAGMENT from '../../../../graphql/fragments/recommended-tiles.gql'
import { THEME_OPTIONS } from '../../../constants'

import styles from './carousel-tile.css'
import rentalBadgeSmall from '../../../../images/neon/rental-badge-small.svg'

import { dataLayerProductClick } from '../../../lib/analytics/datalayer'
import { TESTERS } from '../../../lib/tester-classnames'
import { useIsKid } from '../../../hooks/useIsKid'

const displayOverlay = pipe(anyPass([equals('CHARACTER')]), not)

export default function CarouselTile({
  tile,
  onMouseEnter,
  onMouseLeave,
  isMouseHover,
  contentType,
  subType,
  listTitle,
  index,
  isOnMyList,
  isAuthenticated,
  addToMyList,
  removeFromMyList,
  isVisible,
  onVisibilityChange,
  theme
}) {
  let tileType = contentType || subType

  if (contentType === null && subType === null) {
    tileType = 'Movie4x3'
  }

  if (!tileType) {
    tileType = 'Series'
  }

  const isKid = useIsKid()
  const isCharacterTile = subType === 'CHARACTER'
  const isCircularTile = subType === 'BRAND'
  const tileRecipe = getTileRecipe(isCircularTile ? subType : tile.displayMode)
  const badgeTitle = tile.badge
  const tileHeaderText = isCharacterTile && tile && tile.header
  const isRental = tile.contentItem.isRental

  if (!tile.image) {
    return <div className={styles.preload} data-testid="preload-tile" />
  }

  if (isCircularTile && !tile.circularImage) {
    return <div className={classnames(styles.preload, styles.circularImagePreload)} data-testid="preload-circular-tile" />
  }

  return (
    <div>
      <div
        className={classnames(styles.tile, TESTERS.CAROUSEL_TILE, {
          [styles.circularFrame]: isCircularTile
        })}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        data-lbx-e2e={getDetailsUrl(tile.contentItem)}
      >
        {badgeTitle ? (
          <TileBadge
            title={badgeTitle}
            isRental={isRental}
            isKids={isKid}
          />
        ) : null}

        <Link // eslint-disable-line jsx-a11y/no-static-element-interactions
          to={getDetailsUrl(tile.contentItem)}
          onClick={() => dataLayerProductClick(tile.contentItem, index, listTitle, window.location.pathname)}
          className={classnames(styles.imgWrapper, {
            [styles.characterImageWrapper]: isCharacterTile,
            [styles.circularFrame]: isCircularTile
          })}
        >
          {isCharacterTile ? (
            <ReactImgix
              imgixParams={{ fit: 'clip' }}
              className={styles.characterImage}
              src={tile.image}
            />
          ) : (
            <CarouselImgix
              src={isCircularTile ? tile.circularImage : tile.image}
              recipe={tileRecipe}
              isKids={isKid}
              isCircular={isCircularTile}
            />
          )}

          {tileHeaderText && (
            <div className={isKid ? styles.tileHeaderForKids : styles.tileHeader}>{tileHeaderText}</div>
          )}

          {isRental && (
            <img
              className={classnames(styles.rentalBadge, {
                [styles.rentalBadgeInKidsRentals]: isKid && isMouseHover
              })}
              src={rentalBadgeSmall}
              alt="Rental Content"
            />
          )}

          {displayOverlay(tileType) && (
            <TileOverlay
              tileType={tileType}
              tile={tile}
              isAuthenticated={isAuthenticated}
              isOnMyList={isOnMyList}
              addToMyList={addToMyList}
              removeFromMyList={removeFromMyList}
              display={isMouseHover}
              isRental={isRental}
              isCircular={isCircularTile}
            />
          )}
        </Link>
      </div>
      {!isVisible && <OnVisible onChange={() => onVisibilityChange()} />}
    </div>
  )
}

CarouselTile.propTypes = {
  tile: propType(RECOMMENDED_TILES_FRAGMENT).isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  isMouseHover: PropTypes.bool.isRequired,
  contentType: PropTypes.string,
  subType: PropTypes.string,
  listTitle: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  isOnMyList: PropTypes.bool,
  addToMyList: PropTypes.func,
  removeFromMyList: PropTypes.func,
  isAuthenticated: PropTypes.bool,
  onVisibilityChange: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  theme: PropTypes.oneOf([THEME_OPTIONS.dark, THEME_OPTIONS.light]).isRequired
}

CarouselTile.defaultProps = {
  subType: null,
  contentType: null,
  isOnMyList: false,
  isAuthenticated: false,
  addToMyList: T,
  removeFromMyList: T
}
