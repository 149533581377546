import {
  branch, compose, renderComponent, withHandlers
} from 'recompose'
import { graphql } from 'react-apollo'
import { path } from 'ramda'
import withEnterAccountPassword from '../../../../hoc/with-enter-account-password'
import { LoadingModal as LoadingPanel } from '../../../../components/loading/modal'
import SubscriptionAndPayment from '../../../../components/settings/my-account/subscription-and-payment'
import { checkQueryStatus } from '../../../../lib/utils'

import ACCOUNT_QUERY_WITH_DIR from '../../../../../graphql/queries/account-with-directives.gql'

export const handleUpdateSubscription = ({ accountQuery }) => () => {
  accountQuery.refetch()
}

export default compose(
  withEnterAccountPassword,
  graphql(ACCOUNT_QUERY_WITH_DIR, {
    name: 'accountQuery',
    options: {
      variables: {
        withSubscription: true,
        withClearCachedSubscriptions: true
      },
      fetchPolicy: 'network-only'
    },
    props: ({ accountQuery }) => ({
      accountQuery,
      activeSubscription: path(['account', 'subscription'], accountQuery)
    })
  }),
  branch(
    ({ accountQuery }) => checkQueryStatus(accountQuery),
    renderComponent(LoadingPanel)
  ),
  withHandlers({
    handleUpdateSubscription
  })
)(SubscriptionAndPayment)
